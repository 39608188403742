import React from 'react';
import { userActions, alertActions } from '../../_actions'
import { connect } from 'react-redux'
import { history } from '../../_helpers'
import { withSnackbar } from 'notistack'
import { Copyright } from '../../_components'

import { Grid, CssBaseline, Paper, Avatar, Typography, TextField, Button, Box, LinearProgress } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { withStyles } from '@material-ui/core/styles'

let BG = Math.floor(Math.random() * 3) + 1

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(./load${BG}.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class LoginPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            creds: {
                email: '',
                password: ''
            },
            submitted: false
        }
        if (this.props.loggedIn)
            history.push('/')
    }

    handleChange(e) {
        this.setState(
            {
                creds: {
                    ...this.state.creds,
                    [e.target.name]: e.target.value,
                },
            }
        );

        if (this.props.alert.message !== undefined)
            this.props.clear();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            submitted: true
        }, () => {
            let { creds, submitted } = this.state;
            if (submitted && creds.email && creds.password) {
                this.props.login(creds).then((res) => {
                    if (!res) {
                        this.setState({ submitted: false })
                    }
                })
            }
        })
    }

    render() {
        let { creds, submitted } = this.state;
        let { classes } = this.props
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
          </Typography>
                        <form className={classes.form} noValidate onSubmit={(e) => this.handleSubmit(e)}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={creds.email}
                                onChange={(e) => this.handleChange(e)}
                                helperText={(submitted && !creds.email ? 'Email is required' : '')}
                                error={(submitted && !creds.email ? true : false)}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={creds.password}
                                onChange={(e) => this.handleChange(e)}
                                helperText={(submitted && !creds.password ? 'Password is required' : '')}
                                error={(submitted && !creds.password ? true : false)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
            </Button>
                            {submitted && <LinearProgress color="secondary" />}
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        loggingIn: state.user.loggingIn,
        alert: state.alert
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (creds) => dispatch(userActions.login(creds)),
        logout: () => dispatch(userActions.logout()),
        clear: () => dispatch(alertActions.clear())
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(LoginPage)))