import React from 'react'
import { apiService } from '../_helpers'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

export const FileWidget = (props) => {

  const [progress, setProgress] = React.useState(0)
  const [uploaded, setUploaded] = React.useState(false)
  const [url, setUrl] = React.useState('')

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  async function handleUpload(event) {
    const formData = new FormData()
    formData.append(
      "file",
      event.target.files[0],
      event.target.files[0].name
    )

    setProgress(0)
    setUploaded(false)

    const filePath = await apiService.post('upload', formData, {
      onUploadProgress: function (event) {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }
    })
    setUploaded(true)
    setUrl(filePath.data)
    props.onChange(filePath.data)
  }

  return (
    <div>
      {!uploaded && <div>
        <TextField {...props} fullWidth label={props.label} InputProps={{
          type: 'file'
        }} onChange={handleUpload} />
        <LinearProgressWithLabel value={progress} />
      </div>
      }
      {uploaded && <TextField {...props} label={props.label} value={url} fullWidth InputProps={{
        readOnly: true,
      }} />}
    </div>
  )

}