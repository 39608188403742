import React from 'react'
import { alertActions } from '../../_actions'
import { connect } from 'react-redux'
import { document, scopeCheck, history, apiService } from '../../_helpers'
import pluralize from 'pluralize'
import Form from '@rjsf/material-ui'
import { Appbar, ErrorList } from '../../_components'
import { withSnackbar } from 'notistack'
import { Box, Typography, LinearProgress } from '@material-ui/core'

class CreatePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            schema: null,
            uiSchema: null,
            submitted: false
        }

        this.document = this.props.match.params.document
        this.title = pluralize(this.document)
        this.data = this.props.location.state
        this.formData = {}
        this.updateScopes()
    }

    componentDidUpdate() {
        if (this.props.match.params.document !== this.document) {
            this.document = this.props.match.params.document
            this.title = pluralize(this.document)
            this.data = this.props.location.state
            this.formData = {}
            this.setState({ schema: null, uiSchema: null })
            this.updateScopes()
        }
    }

    async updateScopes() {
        let table = this.document.charAt(0).toUpperCase() + this.document.slice(1)
        let scopes = await scopeCheck(table)
        if (scopes.create || scopes.update) {
            const document = await this.getDocument()
            if (this.data) {
                if (scopes.update){
                    this.setFormData(document.response.formData)
                    if(this.document==='user'){
                        console.log(document.response)
                        document.response.UpdateJSONschema.title = "Update "+table
                        this.setState({ schema: document.response.UpdateJSONschema, uiSchema: {} })
                    }else{
                        document.response.JSONschema.title = "Update "+table
                        this.setState({ schema: document.response.JSONschema, uiSchema: document.response.uiSchema })
                    }
                }else
                    history.push(`/read/${this.document}`)
            }else if(scopes.create){
                document.response.JSONschema.title = "New "+table
                this.setState({ schema: document.response.JSONschema, uiSchema: document.response.uiSchema })
            }else{
                history.push('/')
            }
        } else {
            history.push('/')
        }
    }

    async getDocument() {
        /* let embedUrl = '' */
        let response = await document(this.document)

        for (const i in response) {
            if(typeof response[i] === "function"){
                response[i] = await response[i]()
            }
        }
        /* for (const key in response.embeds) {
            if (response.embeds.hasOwnProperty(key)) {
                embedUrl += `&$embed=${response.embeds[key]}`;
            }
        } */
        return { response/* , embedUrl */ }
    }

    async onSubmit(formData) {
        this.setState({submitted:true})
        if (this.data) {
            await apiService.put(`${this.document}/${this.data._id}`, formData).then(response => {
                this.props.success(`${this.document} updated successfully!`)
                history.push(`/read/${this.document}`)
            }).catch(error=>{
                this.setState({submitted: false})
            })
        } else {
            await apiService.post(`${this.document}`, [formData]).then(response => {
                this.props.success(`${this.document} created successfully!`)
                history.push(`/read/${this.document}`)
            }).catch(error=>{
                this.setState({submitted: false})
            })
        }
    }

    setFormData(formData) {
        if (formData) {
            for (const i of formData) {
                if(this.data[i] && this.data[i]._id !== undefined)
                    this.formData[i] = this.data[i]._id
                else
                    this.formData[i] = this.data[i]
            }
        }
    }

    render() {
        let { schema, uiSchema, submitted } = this.state
        return (
            <Appbar>
                {!submitted && schema && <Form schema={schema} uiSchema={uiSchema} formData={this.formData} onSubmit={(e) => this.onSubmit(e.formData)} ErrorList={ErrorList} noHtml5Validate />}
                {submitted && <Box mt={10}>
                    <Typography variant="h3" align="center" mt={5}>Please wait form is submitting!</Typography>
                    <LinearProgress color="secondary"/>
                </Box>}
            </Appbar>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        error: (msg) => dispatch(alertActions.error(msg)),
        success: (msg) => dispatch(alertActions.success(msg))
    }
}

export default withSnackbar(connect(null, mapDispatchToProps)(CreatePage))