import React from 'react';
import Card from '@material-ui/core/Card'
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel'
import { PermissionsComponent, RGUsersComponent } from './'

export const PuTabs = (props) => {
    const [value, setValue] = React.useState('1')

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    if (props.document === 'user') {
        return (
            <Card>
                <TabContext value={value}>
                    <AppBar color="secondary" position="static">
                        <TabList onChange={handleChange} centered>
                            <Tab label="Permissions" value="1" />
                        </TabList>
                    </AppBar>
                    <TabPanel value="1">
                        <PermissionsComponent {...props} />
                    </TabPanel>
                </TabContext>
            </Card>
        )
    } else {
        return (
            <Card>
                <TabContext value={value}>
                    <AppBar color="secondary" position="static">
                        <TabList onChange={handleChange} centered>
                            <Tab label="Permissions" value="1" />
                            <Tab label="Users" value="2" />
                        </TabList>
                    </AppBar>
                    <TabPanel value="1">
                        <PermissionsComponent {...props} />
                    </TabPanel>
                    <TabPanel value="2">
                        <RGUsersComponent {...props} />
                    </TabPanel>
                </TabContext>
            </Card>
        )
    }
}