import { apiService } from '../_helpers'

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Assign Scope',
    sortable: true,
    cell: row => {
      if (row.assignScope)
        return row.assignScope.join(', ')
    }
  }
]

async function getRoles(){
  let roles = await apiService.get('role')
  roles = roles.data.docs
  return roles.map(r => r.name);
}

const JSONschema = async () => ({
  type: "object",
  "required": ['name'],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    description: {
      type: "string",
      title: "Description"
    },
    assignScope: {
      type: "array",
      title: "Assign Scope",
      items: {
        type: "string",
        enum: await getRoles()
      },
      uniqueItems: true
    }
  }
})

const uiSchema = {

}

const formData = ['name', 'description', 'assignScope']

export const permission = { columns, JSONschema, uiSchema, formData }