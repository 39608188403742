import axios from 'axios'
import { localDB, store } from './'
import { alertActions, userActions } from '../_actions'

export const apiService = axios.create({
    baseURL: 'http://churchgatesite.centralindia.cloudapp.azure.com/iacapi/', //'http://localhost:8000/', // 
    timeout: 60000,
});


//Request interceptor
apiService.interceptors.request.use(async (config) => {
    if(config.url === 'refresh-token' || config.url === 'logout'){
        const refreshToken = await localDB.get('refreshToken')
        config.headers['Authorization'] = refreshToken
        return config
    }
    
    const token = await localDB.get('accessToken')
    if (token) {
        config.headers['Authorization'] = token
    }
    return config
},
    error => {
        return Promise.reject(error)
    })


//Response interceptor
apiService.interceptors.response.use(response => {
    return response
}, async (error) => {
    if(!error.response){
        store.dispatch(alertActions.error(error.toString()))
        return Promise.reject(error)
    }

    const originalRequest = error.config
    //console.log(originalRequest.url)
    if (error.response.status === 401 && (originalRequest.url === 'login' || originalRequest.url === 'refresh-token')) {
        store.dispatch(alertActions.error(error.response.data.message))
        if(originalRequest.url === 'refresh-token')
            store.dispatch(userActions.logout())
        return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== 'login/reset' && originalRequest.url !== 'register/activate' ) {
        originalRequest._retry = true
        
        return apiService.get('refresh-token')
            .then(async (response) => {
                await localDB.set('accessToken', response.headers['x-access-token'])
                if (response.status === 200) {
                    return apiService(originalRequest)
                }
            })
    }else{
        store.dispatch(alertActions.error(error.response.data.message))
    }
    return Promise.reject(error);
})