import React from 'react'
import { apiService } from '../_helpers'
import { AutocompleteUIWidget, FileWidget } from '../_components'

const columns = [
    {
        name: 'Youtube Video ID',
        selector: 'url',
        sortable: true,
    },
    {
        name: 'Thumbnail',
        sortable: false,
        cell: row => {
            if (row.url){
                let src = `http://img.youtube.com/vi/${row.url}/0.jpg`;
                return <img alt={row.title} src={src} width="50px"/>
            }
        }
    },
    {
        name: 'Title',
        selector: 'title',
        sortable: true,
    },
    /* {
        name: 'Report URL',
        selector: 'report'
    }, */
    {
        name: 'Video Type',
        cell: row => {
            if (row.videoType){
                return row.videoType.name
            }
        },
        sortable: false,
    },
    {
        name: 'Country',
        cell: row => {
            if (row.country){
                return row.country.name
            }
        },
        sortable: false,
    },
    {
        name: 'Company',
        cell: row => {
            if (row.company){
                return row.company.name
            }
        },        
        sortable: false,
    },
    {
        name: 'Industry',
        cell: row => {
            if (row.industry){
                return row.industry.name
            }
        },
        sortable: false,
    },
    {
        name: 'Quarter',
        cell: row => {
            if (row.quarter){
                return row.quarter.name
            }
        },
        sortable: false,
    },
    {
        name: 'Published At',
        selector: "publish_at",
        sortable: true,
    }
]

async function getVideoType() {
    let videoTypes = await apiService.get('videotype')
    videoTypes = videoTypes.data.docs
    return videoTypes.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

async function getCompany() {
    let companies = await apiService.get('company')
    companies = companies.data.docs
    return companies.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

async function getQuarter() {
    let industries = await apiService.get('quarter')
    industries = industries.data.docs
    return industries.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

const JSONschema = async () => ({
    type: "object",
    "required": ['title', 'report', 'videoType', 'company', 'quarter', 'publish_at'],
    properties: {
        file: {
            type: "string",
            title: "Upload File"
        },
        url:{
            type: "string",
            title: "Youtube Video ID"
        },
        title: {
            type: "string",
            title: "Title"
        },
        description: {
            type: "string",
            title: "Description"
        },
        report: {
            type: "string",
            title: "Report URL",
            format: "uri"
        },
        videoType: {
            type: "string",
            title: "Video Type",
            anyOf: await getVideoType()
        },
        company: {
            type: "string",
            title: "Company",
            anyOf: await getCompany()
        },
        quarter: {
            type: "string",
            title: "Quarter",
            anyOf: await getQuarter()
        },
        publish_at:{
            type: "string",
            title: "Publish At"
        }
    }
})

const uiSchema = {
    file:{
        "ui:widget": FileWidget
    },
    description: {
        "ui:widget": "textarea"
    },
    videoType: {
        "ui:widget": AutocompleteUIWidget
    },
    company: {
        "ui:widget": AutocompleteUIWidget
    },
    quarter: {
        "ui:widget": AutocompleteUIWidget
    },
    publish_at: {
        "ui:widget": "date"
    }

}

const embeds = [
    'videoType', 'country', 'company', 'industry', 'quarter'
]

const formData = ['url', 'title', 'description', 'report', 'videoType', 'country', 'company', 'industry', 'quarter','publish_at']

export const video = { columns, embeds, JSONschema, uiSchema, formData }