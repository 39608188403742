const columns = [
    {
      name: 'User Name',
      cell: row => {
        if(row.user){
          let name = row.user.firstName
          if(row.user.lastName)
            name += ` `+row.user.lastName
            return name
        }
        return false
      }
    },
    {
      name: 'User Email',
      cell: row => {
        if(row.user){
            return row.user.email
        }
        return false
      }
    },
    {
      name: 'No of Times Logged',
      selector: 'logins'
    },
    {
      name: 'Last Login',
      selector: 'lastLogin',
      sortable: false
    },
  ]
  
  export const audit = { columns }