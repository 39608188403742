import { apiService } from '../_helpers'

const columns = [
  {
    name: 'First Name',
    selector: 'firstName',
    sortable: true,
  },
  {
    name: 'Last Name',
    selector: 'lastName',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Mobile Number',
    selector: 'mobileNo',
    sortable: true,
  },
  {
    name: 'Role',
    cell: row => {
      if (row.role)
        return row.role.name
    },
    sortable: false,
  },
  {
    name: 'Group',
    sortable: false,
    cell: row => {
      if (row.groups)
        return row.groups.map(r => r.group.name).join(', ')
    }
  },
]

async function getRoles(){
  let roles = await apiService.get('role')
  roles = roles.data.docs
  return roles.map(r => ({ type:"string", title: r.name, enum: [r._id] }));
}

const JSONschema = async () => ({
  type: "object",
  "required": ['firstName', 'lastName', 'email', 'mobileNo', 'role', 'password'],
  properties: {
    firstName: {
      type: "string",
      title: "First Name"
    },
    lastName: {
      type: "string",
      title: "Last Name"
    },
    email: {
      type: "string",
      title: "Email"
    },
    mobileNo: {
      type: "number",
      title: "Mobile Number"
    },
    role: {
      type: "string",
      title: "Assign Role",
      anyOf: await getRoles()
    },
    password: {
      type: "string",
      title: "Password"
    }
  }
})

const UpdateJSONschema = async () => ({
  type: "object",
  "required": ['firstName', 'lastName', 'mobileNo', 'role'],
  properties: {
    firstName: {
      type: "string",
      title: "First Name"
    },
    lastName: {
      type: "string",
      title: "Last Name"
    },
    mobileNo: {
      type: "number",
      title: "Mobile Number"
    },
    role: {
      type: "string",
      title: "Assign Role",
      anyOf: await getRoles()
    }
  }
})

const embeds = [
  'role', 'groups'
]

const uiSchema = {
  password: {
    "ui:widget": "password"
  }
}

const formData = ['firstName', 'lastName', 'mobileNo', 'role', 'password']

export const user = { columns, embeds, JSONschema, uiSchema, formData, UpdateJSONschema }