export const authAttemptColumns = [
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'IP Address',
    selector: 'ip',
    sortable: true,
  },
  {
    name: 'Timestamp',
    selector: 'time',
    sortable: true,
  },
];