import React from 'react';
import { alertActions } from '../../_actions'
import { connect } from 'react-redux'
import { history } from '../../_helpers'
import { withSnackbar } from 'notistack'
import { Copyright } from '../../_components'
import { apiService } from '../../_helpers'

import { Grid, CssBaseline, Paper, Avatar, Typography, TextField, Button, Box } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { withStyles } from '@material-ui/core/styles'

let BG = Math.floor(Math.random() * 3) + 1

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(./load${BG}.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class ResetPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            creds: {
                newpassword: '',
                password: ''
            },
            verifying: true,
            submitted: false
        }
        if (this.props.loggedIn)
            history.push('/')
    }

    handleChange(e) {
        this.setState(
            {
                creds: {
                    ...this.state.creds,
                    [e.target.name]: e.target.value,
                },
            }
        );

        if (this.props.alert.message !== undefined)
            this.props.clear();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            submitted: true
        }, async () => {
            let { creds, submitted } = this.state;
            if (submitted && creds.newpassword && creds.password) {
                if (creds.newpassword === creds.password) {
                    await apiService.post(`login/reset`, { ...this.props.match.params, password: creds.password }).then(() => {
                        this.setState({
                            verifying: false
                        })
                    })
                } else {
                    this.props.error("Password not matched!")
                }
            }
        })
    }

    render() {
        let { creds, submitted, verifying } = this.state;
        let { classes } = this.props
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reset Password
          </Typography>
                        {verifying && <form className={classes.form} noValidate onSubmit={(e) => this.handleSubmit(e)}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="newpassword"
                                label="New Password"
                                name="newpassword"
                                type="password"
                                autoComplete="current-password"
                                autoFocus
                                value={creds.newpassword}
                                onChange={(e) => this.handleChange(e)}
                                helperText={(submitted && !creds.newpassword ? 'New password is required' : '')}
                                error={(submitted && !creds.newpassword ? true : false)}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Confirm Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={creds.password}
                                onChange={(e) => this.handleChange(e)}
                                helperText={(submitted && !creds.password ? 'Confirm password is required' : '')}
                                error={(submitted && !creds.password ? true : false)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Change Password
            </Button>
                        </form>}
                        {!verifying && <Box mt={5}>
                            <strong>Your account password has been changed successfully.</strong>
                            <br />
                            <small> you may login the investor access channel mobile app with updated password </small>
                        </Box>}
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        alert: state.alert
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clear: () => dispatch(alertActions.clear()),
        error: (msg) => dispatch(alertActions.error(msg))
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ResetPage)))