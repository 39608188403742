import React from 'react'
import DnsIcon from '@material-ui/icons/Dns'

const columns = [
  {
    name: "",
    sortable: true,
    cell: row => {
      let matches = row.name.match(/\b(\w)/g)
      let acronym = matches.join('')
      return <span data-letters={acronym}></span>
    },
    width: '50px'
  },
  {
    name: "Name",
    selector: "name",
    sortable: true
  },
  {
    name: "Icon",
    sortable: false,
    cell: row => {
      if(row.icon){
        return <img alt={row.name} src={row.icon}  width="50px"/>
      }else{
        return <DnsIcon style={{ fontSize: 40 }}/>
      }
    }
  }
]

const JSONschema = {
  type: "object",
  "required": ['name'],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    icon: {
      type: "string",
      format: "data-url",
      title: "Icon"
    }
  }
}
const uiSchema = {
  icon: {
    "ui:options": {
      "accept": ".png,.jpeg,.jpg"
    }
  }
}

const formData = ['name', 'icon']

export const industry = { columns, JSONschema, formData, uiSchema }