import React from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export const AutocompleteUIWidget = (props) => {
    function selectedValue() {
        if (props.schema.multiple) return []
        return props.schema.anyOf.find(r => r.enum[0] === props.value) ? props.schema.anyOf.find(r => r.enum[0] === props.value) : null
    }
    function selectedInputValue() {
        if (props.schema.multiple)
            return props.schema.items.anyOf.find(r => r.enum[0] === props.value) ? props.schema.items.anyOf.find(r => r.enum[0] === props.value) :[]
        return props.schema.anyOf.find(r => r.enum[0] === props.value) ? props.schema.anyOf.find(r => r.enum[0] === props.value) : ''
    }

    const [value, setValue] = React.useState(selectedValue());
    const [inputValue, setInputValue] = React.useState(selectedInputValue());
    if (props.schema.multiple) {
        return (
            <Autocomplete
                id={props.id}
                multiple
                disableCloseOnSelect
                options={props.schema.items.anyOf}
                getOptionLabel={(option) => option.title}
                onChange={(event, newValue) => {
                    setValue([...newValue])
                    let vals = newValue.map(r => r.enum[0] )
                    props.onChange(vals)
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                value={value}
                defaultValue={props.schema.items.anyOf.find(r => r.enum[0] === props.value)}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                renderInput={(params) => <TextField required={props.required} {...params} label={props.label} />}
            />
        );
    } else {
        return (
            <Autocomplete
                id={props.id}
                options={props.schema.anyOf}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => {
                    props.onChange(value.enum[0])
                    return true
                }}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                    props.onChange(newValue.enum[0])
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                defaultValue={props.schema.anyOf.find(r => r.enum[0] === props.value)}
                renderInput={(params) => <TextField required={props.required} {...params} label={props.label} />}
            />
        );
    }
}