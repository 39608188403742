import React from 'react'
import clsx from 'clsx'
import { userActions } from '../../_actions'
import { connect } from 'react-redux'
import SideMenu from '../sidemenu'

import { withStyles, AppBar, Toolbar, IconButton, Typography, Drawer, Divider, Box } from '@material-ui/core'
import { Menu as MenuIcon, ExitToApp, ChevronLeft } from '@material-ui/icons'

const drawerWidth = 240;
const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        marginLeft: 80,
        width: `calc(100% - 80px)`,
        height: '100vh',
        overflow: 'unset',
    },
    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
})

class Appbar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    handleDrawerOpen() {
        this.setState({
            open: true
        })
    }

    handleDrawerClose() {
        this.setState({
            open: false
        })
    }

    logout() {
        this.props.logout();
    }

    render() {
        const { classes } = this.props
        let { open } = this.state
        let userFirstName = JSON.parse(localStorage.getItem('user')).firstName
        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => this.handleDrawerOpen()}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {!open && "Investor Access Channel"}
                        </Typography>
                        <Box>
                            Hi, {userFirstName}
                            <IconButton color="inherit" onClick={() => this.logout()}>
                                <ExitToApp />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Investor Access Channel
                        </Typography>
                        <IconButton onClick={() => this.handleDrawerClose()}>
                            <ChevronLeft />
                        </IconButton>
                    </div>
                    <Divider />
                    <SideMenu />
                    <Divider />
                </Drawer>
                <main className={clsx(classes.content, open && classes.contentShift)}>
                    <div className={classes.appBarSpacer} />
                    {this.props.children}
                </main>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(userActions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Appbar))