import React from 'react'
import { scopeCheck, apiService, history, document } from '../../_helpers'
import DataTable from 'react-data-table-component'
import pluralize from 'pluralize'
import { IconButton, Paper, InputBase, LinearProgress } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import RefreshIcon from '@material-ui/icons/Refresh'
import { green } from '@material-ui/core/colors'
import { withSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import { Appbar, PuTabs } from '../../_components'
import Box from '@material-ui/core/Box'

const useStyles = (theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        marginLeft: theme.spacing(2)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    marginRight: {
        marginRight: '10px'
    }
});

class ReadPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            create: false,
            read: false,
            update: false,
            delete: false,

            getPermissions: false,
            addPermissions: false,
            removePermissions: false,

            data: false,
            loading: false,
            page: 1,
            totalRows: 0,
            perPage: 10,
            filter: false,

            selectedRows: [],
            toggledClearRows: false,

            open: false,
            id: false
        }

        this.document = this.props.match.params.document
        this.title = pluralize(this.document)
        this.embedUrl = ''

        this.contextActions = <span></span>;

        this.handleClose = this.handleClose.bind(this)

        this.updateScopes()
    }

    componentDidUpdate() {
        if (this.props.match.params.document !== this.document) {
            this.document = this.props.match.params.document
            this.title = pluralize(this.document)
            this.updateScopes()
        }
    }

    async updateScopes() {
        let table = this.document.charAt(0).toUpperCase() + this.document.slice(1)
        let scopes = await scopeCheck(table)

        if (scopes.read) {
            let document = await this.getDocument()

            this.embedUrl = document.embedUrl

            let data = await this.getData()

            let btns = this.preRenderColumns(scopes)
            let ctxtBtns = this.contextMenu()
            let cols = [...document.response.columns]

            if (scopes.canActivate || scopes.canDeactivate) cols.push(btns.activeBtn)
            if (scopes.canEnable || scopes.canDisable) cols.push(btns.enableBtn)

            if (scopes.update) cols.push(btns.updateBtn)
            if (scopes.delete) {
                cols.push(btns.deleteBtn)
                this.contextActions = <span>
                    {/* ctxtBtns.RecoverCtxtBtn */}
                    {ctxtBtns.DeleteCtxtBtn}
                </span>
            }

            this.setState({ ...scopes, properName: table, page: 1, columns: cols, embeds: document.response.embeds, data: data.data.docs, totalRows: data.data.items.total, loading: false, filter: false }, () => {
            })
        } else {
            history.push('/')
        }
    }

    async getDocument() {
        let embedUrl = ''
        let response = await document(this.document)
        for (const key in response.embeds) {
            if (response.embeds.hasOwnProperty(key)) {
                embedUrl += `&$embed=${response.embeds[key]}`;
            }
        }
        return { response, embedUrl }
    }

    contextMenu(scopes) {
        const RecoverCtxtBtn = <IconButton
            color="inherit"
            onClick={() => this.recoverSelected()}
        >
            <RestoreFromTrashIcon />
        </IconButton>

        const DeleteCtxtBtn = <IconButton
            color="secondary"
            onClick={() => this.deleteSelected()}
        >
            <DeleteIcon />
        </IconButton>

        return { RecoverCtxtBtn, DeleteCtxtBtn }
    }

    preRenderColumns(scopes) {
        let activeBtn = {
            name: 'De/activate',
            button: true,
            cell: row => {
                if (row.isActive && scopes.canDeactivate) {
                    return <IconButton color="secondary" title="Deactivate" onClick={() => this.onDeactivate(row._id, row.email)}>
                        <BlockIcon />
                    </IconButton>
                } else if (scopes.canActivate) {
                    return <IconButton style={{ color: green[500] }} title="Activate" onClick={() => this.onActivate(row._id, row.email)}>
                        <CheckCircleIcon />
                    </IconButton>
                }
            }
        }
        let enableBtn = {
            name: 'Dis/en-able',
            button: true,
            cell: row => {
                if (row.isEnabled && scopes.canDisable) {
                    return <IconButton color="secondary" title="Disable" onClick={() => this.onDisable(row._id, row.email)}>
                        <BlockIcon />
                    </IconButton>
                } else if (scopes.canEnable) {
                    return <IconButton style={{ color: green[500] }} title="Enable" onClick={() => this.onEnable(row._id, row.email)}>
                        <CheckCircleIcon />
                    </IconButton>
                }
            }
        }
        let updateBtn = {
            name: 'Update',
            button: true,
            cell: row => <IconButton color="primary" onClick={() => this.onUpdate(row)}>
                <EditIcon />
            </IconButton>
        }
        let deleteBtn = {
            name: 'Delete',
            button: true,
            cell: row => {
                if (!row.deletedAt) {
                    return <IconButton color="secondary" onClick={() => this.onDelete(row._id)}>
                        <DeleteIcon />
                    </IconButton>
                } else {
                    return <IconButton color="inherit" onClick={() => this.onRecover(row._id)}>
                        <RestoreFromTrashIcon />
                    </IconButton>
                }
            },
        }

        return { updateBtn, deleteBtn, activeBtn, enableBtn }
    }

    async getData() {
        this.setState({ loading: true });
        const { perPage, page, filter } = this.state;

        let text = ''
        if (filter) {
            text = `&$text=${filter}`
        }

        let data = await apiService.get(`${this.document}?$page=${page}&$limit=${perPage}${this.embedUrl}${text}`)
        return data
    }

    handlePageChange = async page => {
        this.setState({ page }, () =>{
            this.refreshData()
        });
    }

    handlePerRowsChange = async (perPage, page) => {

        if(isNaN(page)){
            page = 1
        }
        if(isNaN(perPage)){
            perPage = 10000000
        }

        this.setState({ page, perPage }, ()=>{
            this.refreshData()
        });
    }

    handleRowSelected = (selected) => {
        this.setState({ selectedRows: selected.selectedRows, toggledClearRows: false })
    }

    rowDisabledCriteria = row => row.isDeleted

    onUpdate(row) {
        history.push(`/update/${this.document}`, row)
    }

    onActivate(id, email) {
        if (this.state.update) {
            apiService.put(`${this.document}/${id}/activate`).then(response => {
                this.props.enqueueSnackbar(`User with email ${email} activated successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    onDeactivate(id, email) {
        if (this.state.update) {
            apiService.put(`${this.document}/${id}/deactivate`).then(response => {
                this.props.enqueueSnackbar(`User with email ${email} deactivated successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    onEnable(id, email) {
        if (this.state.update) {
            apiService.put(`${this.document}/${id}/enable`).then(response => {
                this.props.enqueueSnackbar(`User with email ${email} enabled successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    onDisable(id, email) {
        if (this.state.update) {
            apiService.put(`${this.document}/${id}/disable`).then(response => {
                this.props.enqueueSnackbar(`User with email ${email} disabled successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    onDelete(id) {
        if (this.state.delete) {
            this.setState({open: true, id: id})
        }
    }

    onDeleteConfirm() {
        if (this.state.delete) {
            let {id} = this.state;
            apiService.delete(`${this.document}/${id}`, {data: {hardDelete: true}}).then(response => {
                this.props.enqueueSnackbar(`Record with id ${id} deleted successfully!`, {
                    variant: "info",
                })
                this.setState({open: false})
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    handleClose(){
        this.setState({open: false, id: false})
    }

    onRecover(id) {
        if (this.state.update) {
            apiService.put(`${this.document}/${id}/recover`, {}).then(response => {
                this.props.enqueueSnackbar(`Record with id ${id} recovered successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    deleteSelected() {
        const { selectedRows, toggledClearRows } = this.state;
        const rows = selectedRows.map(r => ({ _id: r._id, hardDelete: true }));
        this.setState({ toggledClearRows: !toggledClearRows })
        if (this.state.delete) {
            apiService.delete(`${this.document}`, { data: rows }).then(response => {
                this.props.enqueueSnackbar(`Records deleted successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        } else {
            this.props.enqueueSnackbar(`Delete access not allowed!`, {
                variant: "error",
            })
        }
    }

    /* recoverSelected() {
        const { selectedRows, toggledClearRows } = this.state;
        const rows = selectedRows.map(r => r._id);
        this.setState({ toggledClearRows: !toggledClearRows })
        if (this.state.update) {
            apiService.put(`${this.document}/recover`, rows).then(response => {
                this.props.enqueueSnackbar(`Records recovered successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        } else {
            this.props.enqueueSnackbar(`Update access not allowed!`, {
                variant: "error",
            })
        }
    } */

    filterText(text) {
        this.setState({ filter: text })
    }

    async refreshData(refresh=false) {
        if(refresh){
            this.setState({ filter: false })
        }
        
        const response = await this.getData()

        this.setState({
            loading: false,
            data: response.data.docs,
            totalRows: response.data.items.total,
        });
    }

    redirectTo(to) {
        history.push(to);
    }

    searchOnEnter(e) {
        if (e.key === 'Enter') {
            this.refreshData()
        }
    }

    render() {
        let { create, read, data, totalRows, loading, columns, properName, filter, toggledClearRows, getPermissions, open } = this.state
        let title = this.title.charAt(0).toUpperCase() + this.title.slice(1)
        let document = this.document.charAt(0).toUpperCase() + this.document.slice(1)
        if (!filter) filter = ''
        let { classes } = this.props
        return (
            <Appbar>
                {
                    read && data && <DataTable
                        title={
                            <Box display="flex" p={1}>
                                <Box p={1} flexGrow={1}>
                                    {title}
                                </Box>
                                <Box p={1} flexGrow={1}>
                                    <Paper component="div" className={classes.root}>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Search data"
                                            inputProps={{ 'aria-label': 'search' }}
                                            value={filter}
                                            onChange={(e) => this.filterText(e.target.value)}
                                            onKeyPress={(e) => this.searchOnEnter(e)}
                                        />
                                        <IconButton type="reset" className={classes.iconButton} aria-label="search" onClick={() => this.refreshData()}>
                                            <SearchIcon />
                                        </IconButton>
                                        {
                                            filter && <IconButton type="reset" className={classes.iconButton} aria-label="reset" onClick={() => this.refreshData(true)}>
                                                <ClearIcon />
                                            </IconButton>
                                        }
                                    </Paper>
                                </Box>
                                <Box p={1}>
                                    {create && <button className='btn btn-primary' onClick={() => this.redirectTo(`/create/${this.document}`)}>Create {properName}</button>}
                                    <IconButton color="primary" onClick={() => this.refreshData(true)}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        }
                        columns={columns}
                        fixedHeader
                        /* fixedHeaderScrollHeight="500px" */
                        data={data}
                        selectableRows={this.document !== 'user'}
                        onSelectedRowsChange={this.handleRowSelected}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100, 500, 1000, 2000, 'All']}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        highlightOnHover
                        contextActions={this.document !== 'user' && this.contextActions}
                        clearSelectedRows={toggledClearRows}
                        expandableRows={getPermissions}
                        expandOnRowClicked
                        expandableRowsComponent={
                            <PuTabs document={this.document} />
                        }
                        progressComponent={<div style={{ width: "75%" }}><LinearProgress /></div>}

                    /* [
                        <PermissionsComponent document={this.document} />,
                        <RGUsersComponent document={this.document} />
                    ] */
                    />
                }
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete {document}?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete?<br/>
                            <strong>This can't be undone!</strong>
          </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onDeleteConfirm()} color="primary">
                            Delete
          </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Cancel
          </Button>
                    </DialogActions>
                </Dialog>
            </Appbar>
        );
    }
}

export default withSnackbar((withStyles(useStyles)(ReadPage)))