import { userConstants } from '../_constants'
import { alertActions } from '../_actions'
import { apiService, history, localDB } from '../_helpers'

export const userActions = {
    login,
    logout
}

function login({ email, password }) {
    return async dispatch => {
        dispatch(request({ email }));
        let res = false;
        await apiService.post('login', { email, password })
            .then(async (response) => {
                if(response.data.user.role.rank===2){
                    dispatch(alertActions.error("You are not authorized."))
                    return
                }
                await localDB.set('user', response.data.user)
                await localDB.set('refreshToken', response.data.refreshToken)
                await localDB.set('accessToken', response.data.accessToken)
                await localDB.set('scope', response.data.scope)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                dispatch(success(response.data))
                history.push('/');
                res = true;
            })
            .catch(function (error) {
                if(error.response){
                    dispatch(failure(error.response.data.message))
                }else{
                    dispatch(failure(error.toString()))
                }
            })
        return res;
    }

    function request(user) {
        return {
            type: userConstants.LOGIN_REQUEST,
            user
        }
    }

    function success({user}) {
        return {
            type: userConstants.LOGIN_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: userConstants.LOGIN_FAILURE,
            error
        }
    }
}

function logout() {
    return async (dispatch) => {
        const refreshToken = await localDB.get('refreshToken')
        if(refreshToken===undefined){
            await localDB.clear();
            localStorage.clear();
            dispatch(success())
            history.push('/')
            return
        }
        apiService.delete('logout')
            .then(async (response) => {
                await localDB.clear();
                localStorage.clear();
                dispatch(success())
                history.push('/');
            })
            .catch(async (error) => {
                await localDB.clear();
                localStorage.clear();
                dispatch(success())
                history.push('/');
            })
    }

    function success() {
        return {
            type: userConstants.LOGOUT
        }
    }
}