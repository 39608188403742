import {
    user,
    role,
    permission,
    group,
    assignPermission,
    rgUsers,

    company,
    video,
    audit,

    country,
    industry,
    quarter,
    videotype,

} from '../_modals'

export async function document(table) {
    switch (table) {
        case 'role':
            return role
        case 'user':
            return user
        case 'permission':
            return permission
        case 'group':
            return group
        case 'assignPermission':
            return assignPermission
        case 'rgUsers':
            return rgUsers

        case 'country':
            return country
        case 'company':
            return company
        case 'industry':
            return industry
        case 'videotype':
            return videotype
        case 'quarter':
            return quarter

        case 'video':
            return video
        case 'audits':
            return audit
        default:
            return {}
    }
}