import React from 'react';
import { Copyright } from '../../_components'
import { apiService } from '../../_helpers'

import { Grid, CssBaseline, Paper, Avatar, Typography, Box } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { withStyles } from '@material-ui/core/styles'

let BG = Math.floor(Math.random() * 3) + 1

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(./load${BG}.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class ActivatePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            verifying: true,
            msg: 1,
            mssg: false
        }
        this.verify()
    }

    async verify() {
        await apiService.post(`register/activate`, this.props.match.params).then(() => {
            this.setState({
                verifying: false,
                msg: 2,
                mssg: false
            })
        }).catch((err) => {
            this.setState({
                verifying: false,
                msg: 3,
                mssg: err.response.data.message
            })
        })

    }

    render() {
        let { verifying, msg, mssg } = this.state
        let { classes } = this.props
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Account Activation
          </Typography>
                        {verifying && msg === 1 && <Box mt={5}>
                            <strong>Please wait, Verifying!</strong>
                        </Box>}
                        {!verifying && msg === 3 && <Box mt={5}>
                            <strong>{mssg}</strong>
                        </Box>}
                        {!verifying && msg === 2 && <Box mt={5}>
                            <strong>Your account has been successfully verified.</strong>
                            <br />
                            <small> you may start using the investor access channel mobile app </small>
                        </Box>}
                        <Box mt={10}>
                            <Copyright />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(useStyles)(ActivatePage)