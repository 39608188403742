import { openDB } from 'idb';
 
const dbPromise = openDB('ap-store', 1, {
  upgrade(db) {
    db.createObjectStore('admin_panel');
  },
});
 
export const localDB = {
  async get(key) {
    return (await dbPromise).get('admin_panel', key);
  },
  async set(key, val) {
    return (await dbPromise).put('admin_panel', val, key);
  },
  async delete(key) {
    return (await dbPromise).delete('admin_panel', key);
  },
  async clear() {
    return (await dbPromise).clear('admin_panel');
  },
  async keys() {
    return (await dbPromise).getAllKeys('admin_panel');
  },
};