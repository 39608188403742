const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'description'
  },
]

const JSONschema = {
  type: "object",
  "required": ['name'],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    description: {
      type: "string",
      title: "Description"
    },
  }
}

const formData = ['name', 'description']

export const group = { columns, JSONschema, formData }