import { apiService, localDB } from '../_helpers'
import { AutocompleteUIWidget } from '../_components'

const columns = [
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Description',
        selector: 'description',
        sortable: true,
    },
    {
        name: 'Assign Scope',
        sortable: true,
        cell: row => {
            if (row.assignScope)
                return row.assignScope.join(', ')
        }
    },
    {
        name: 'State',
        sortable: true,
        cell: row => {
            if (row.role_permission)
                return row.role_permission.state
            if (row.group_permission)
                return row.group_permission.state
        }
    }
]

async function getRoles() {
    let roles = await apiService.get('role')
    roles = roles.data.docs
    return roles.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

async function getUsers() {
    let users = await apiService.get('user')
    users = users.data.docs
    return users.map(r => ({ type: "string", title: r.email, enum: [r._id] }));
}

async function getGroups() {
    let groups = await apiService.get('group')
    groups = groups.data.docs
    return groups.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

async function getPermissions() {
    const user = await localDB.get('user')
    let permissions = await apiService.get(`permission?$where={"assignScope": "${user.role.name}"}`)
    permissions = permissions.data.docs
    return permissions.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

const JSONschemaRole = async () => ({
    type: "object",
    "required": ['ownerId', 'childId', 'state'],
    properties: {
        ownerId: {
            type: "string",
            title: "Role",
            anyOf: await getRoles()
        },
        childId: {
            type: "array",
            title: "Permissions",
            multiple: true,
            items: {
                type: "string",
                anyOf: await getPermissions()
            },
            uniqueItems: true
        },
        state: {
            type: "string",
            title: "State",
            enum: ['Included', 'Excluded', 'Forbidden']
        }
    }
})

const uiSchemaRole = {
    ownerId: {
        "ui:readonly": true,
    },
    childId: {
        "ui:widget": AutocompleteUIWidget
    }

}

const JSONschemaGroup = async () => ({
    type: "object",
    "required": ['ownerId', 'childId', 'state'],
    properties: {
        ownerId: {
            type: "string",
            title: "Group",
            anyOf: await getGroups()
        },
        childId: {
            type: "array",
            title: "Permissions",
            multiple: true,
            items: {
                type: "string",
                anyOf: await getPermissions()
            },
            uniqueItems: true
        },
        state: {
            type: "string",
            title: "State",
            enum: ['Included', 'Excluded', 'Forbidden']
        }
    }
})

const uiSchemaGroup = {
    ownerId: {
        "ui:readonly": true,
    },
    childId: {
        "ui:widget": AutocompleteUIWidget
    }

}

const JSONschemaUser = async () => ({
    type: "object",
    "required": ['ownerId', 'childId', 'state'],
    properties: {
        ownerId: {
            type: "string",
            title: "User",
            anyOf: await getUsers()
        },
        childId: {
            type: "array",
            title: "Permissions",
            multiple: true,
            items: {
                type: "string",
                anyOf: await getPermissions()
            },
            uniqueItems: true
        },
        state: {
            type: "string",
            title: "State",
            enum: ['Included', 'Excluded', 'Forbidden']
        }
    }
})

const uiSchemaUser = {
    ownerId: {
        "ui:readonly": true,
    },
    childId: {
        "ui:widget": AutocompleteUIWidget
    }

}

export const assignPermission = { columns, JSONschemaRole, uiSchemaRole, JSONschemaGroup, uiSchemaGroup, JSONschemaUser, uiSchemaUser }