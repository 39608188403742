import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component: Component, ...rest }) => {    
    let user = localStorage.getItem('user')
    const loggedIn = useSelector(state => state.user.loggedIn);
    
    return <Route {...rest} render={props => (
        loggedIn && user
            ? <Component {...props}/>
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
}