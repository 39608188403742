import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { Appbar } from '../../_components'

class DashboardPage extends React.Component {
    
    render() {
        return (
            <div>
                <CssBaseline />
                <Appbar></Appbar>
            </div>
        )
    }
}

export default DashboardPage