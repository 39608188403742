const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Rank',
    selector: 'rank',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    hide: "md"
  },
]

const JSONschema = {
  title: "Role",
  type: "object",
  "required": ['name', 'rank'],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    rank: {
      type: "number",
      title: "Rank"
    },
    description: {
      type: "string",
      title: "Description"
    },
  }
}

const formData = ['name', 'rank', 'description']

export const role = { columns, JSONschema, formData }