import React from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { store } from './_helpers';
import { render } from 'react-dom';
import './index.css';
import App from './App/App';

import 'bootstrap/dist/css/bootstrap.min.css';

render(
  <Provider store={store}>
    <SnackbarProvider autoHideDuration={3000} maxSnack={10} preventDuplicate>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);
