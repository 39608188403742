import React from 'react'
import { alertActions } from '../../_actions'
import { connect } from 'react-redux'
import { document, scopeCheck, history, apiService } from '../../_helpers'
import pluralize from 'pluralize'
import Form from '@rjsf/material-ui'
import { Appbar, ErrorList } from '../../_components'
import { withSnackbar } from 'notistack'

class RgUserPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            schema: null,
            uiSchema: null
        }

        this.document = this.props.match.params.document
        this.title = `Add User in ` + pluralize(this.document)
        this.ownerId = this.props.match.params.ownerId
        this.data = {ownerId: this.ownerId}
        this.formData = {}
        this.updateScopes()
    }

    componentDidUpdate() {
        if (this.props.match.params.document !== this.document) {
            this.document = this.props.match.params.document
            this.ownerId = this.props.match.params.ownerId
            this.title = `Add User in ` + pluralize(this.document)
            this.data = {ownerId: this.ownerId}
            this.formData = {}
            this.setState({ schema: null, uiSchema: null })
            this.updateScopes()
        }
    }

    async updateScopes() {
        let table = this.document.charAt(0).toUpperCase() + this.document.slice(1)
        let scopes = await scopeCheck(table)
        if (scopes.addPermissions || scopes.update) {
            const document = await this.getDocument()
            this.setFormData(['ownerId'])
            if (this.document === 'role') {
                document.response.JSONschemaRole.title = `Add User in ` + table
                this.setState({ schema: document.response.JSONschemaRole, uiSchema: document.response.uiSchemaRole })
            }
            else if (this.document === 'group') {
                document.response.JSONschemaGroup.title = `Add User in ` + table
                this.setState({ schema: document.response.JSONschemaGroup, uiSchema: document.response.uiSchemaGroup })
            }
        } else {
            history.push('/')
        }
    }

    async getDocument() {
        let response = await document('rgUsers')

        for (const i in response) {
            if (typeof response[i] === "function") {
                response[i] = await response[i]()
            }
        }
        return { response }
    }

    setFormData(formData) {
        if (formData) {
            for (const i of formData) {
                if(this.data[i] && this.data[i]._id !== undefined)
                    this.formData[i] = this.data[i]._id
                else
                    this.formData[i] = this.data[i]
            }
        }
    }

    async onSubmit(formData) {
        await apiService.post(`${this.document}/${this.ownerId}/user`, formData.childId)
        this.props.success(`User added successfully to ${this.document}!`)
        history.push(`/read/${this.document}`)
    }

    render() {
        let { schema, uiSchema } = this.state
        return (
            <Appbar>
                {schema && <Form schema={schema} uiSchema={uiSchema} formData={this.formData} onSubmit={(e) => this.onSubmit(e.formData)} ErrorList={ErrorList} noHtml5Validate />}
            </Appbar>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        error: (msg) => dispatch(alertActions.error(msg)),
        success: (msg) => dispatch(alertActions.success(msg))
    }
}

export default withSnackbar(connect(null, mapDispatchToProps)(RgUserPage))