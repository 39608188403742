import { apiService } from '../_helpers'
import { AutocompleteUIWidget } from '../_components'

const columns = [
    {
        name: "User",
        selector: "user.firstName",
        sortable: true
    },
    {
        name: "Company",
        selector: "company.name",
        sortable: true
    }
]



async function getUsers() {
    let users = await apiService.get('user')
    users = users.data.docs
    return users.map(r => ({ type: "string", title: r.firstName, enum: [r._id] }));
}

async function getCompany() {
    let companies = await apiService.get('company')
    companies = companies.data.docs
    return companies.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

const JSONschema = async () => ({
    type: "object",
    "required": ['user', 'company'],
    properties: {
        user: {
            type: "string",
            title: "User",
            anyOf: await getUsers()
        },
        company: {
            type: "string",
            title: "Company",
            anyOf: await getCompany()
        },
    }
})

const uiSchema = {
    user: {
        "ui:widget": AutocompleteUIWidget
    },
    company: {
        "ui:widget": AutocompleteUIWidget
    },
}

const embeds = [
    'user', 'company'
]

const formData = ['user', 'company']

export const subscribe = { columns, embeds, JSONschema, uiSchema, formData }