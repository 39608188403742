import React from 'react'
import { apiService } from '../_helpers'
import { AutocompleteUIWidget } from '../_components'
import BusinessIcon from '@material-ui/icons/Business'

const columns = [
  {
    name: "",
    sortable: true,
    cell: row => {
      let matches = row.name.match(/\b(\w)/g)
      let acronym = matches.join('')
      return <span data-letters={acronym}></span>
    },
    width: '50px'
  },
  {
    name: "Name",
    selector: "name",
    sortable: true
  },
  {
    name: "Icon",
    sortable: false,
    cell: row => {
      if (row.icon) {
        return <img alt={row.name} src={row.icon} width="50px" />
      } else {
        return <BusinessIcon style={{ fontSize: 40 }} />
      }
    }
  },
  {
    name: 'Country',
    selector: 'country.name',
    sortable: true,
  },
  {
    name: 'Industry',
    selector: 'industry.name',
    sortable: true,
  },
  {
    name: 'Company Page',
    selector: 'page',
  },
  {
    name: 'Page URL',
    selector: 'url'
  }
]


async function getCountry() {
  let countries = await apiService.get('country')
  countries = countries.data.docs
  return countries.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

async function getIndustry() {
  let industries = await apiService.get('industry')
  industries = industries.data.docs
  return industries.map(r => ({ type: "string", title: r.name, enum: [r._id] }));
}

const JSONschema = async () => ({
  type: "object",
  required: ['name', 'country', 'industry'],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    icon: {
      type: "string",
      format: "data-url",
      title: "Icon"
    },
    country: {
      type: "string",
      title: "Country",
      anyOf: await getCountry()
    },
    industry: {
      type: "string",
      title: "Industry",
      anyOf: await getIndustry()
    },
    page: {
      title: "Company Page",
      type: "string",
      enum: [
        "Enabled",
        "Disabled"
      ],
    }
  },
  dependencies: {
    page: {
      oneOf: [
        {
          properties: {
            page: {
              enum: [
                "Enabled"
              ]
            },
            url:{
              title: "Page URL",
              type: "string"
            }
          },
          required: ["url"]
        }
      ]
    }
  }
})

const uiSchema = {
  icon: {
    "ui:options": {
      "accept": ".png,.jpeg,.jpg"
    }
  },
  country: {
    "ui:widget": AutocompleteUIWidget
  },
  industry: {
    "ui:widget": AutocompleteUIWidget
  }
}

const embeds = [
  'country', 'industry'
]

const formData = ['name', 'icon', 'country', 'industry', 'page', 'url']

export const company = { columns, JSONschema, formData, uiSchema, embeds }