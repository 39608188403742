import React from 'react'
import { history, localDB } from '../../_helpers'
import { ListItem, List, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Person as PersonIcon, Dashboard as DashboardIcon} from '@material-ui/icons'
import BusinessIcon from '@material-ui/icons/Business'
import YouTubeIcon from '@material-ui/icons/YouTube'
import LanguageIcon from '@material-ui/icons/Language'
import DnsIcon from '@material-ui/icons/Dns'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import ListAltIcon from '@material-ui/icons/ListAlt'
import ListIcon from '@material-ui/icons/List';

class SideMenu extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            scopes: false
        }
        this.getScopes()
    }

    async getScopes(){
        const scopes = await localDB.get('scope');
        this.setState({scopes}, () => {
        })
    }

    redirectTo(to) {
        history.push(to);
    }

    render(){
        const {scopes} = this.state
        return <List>
        <ListItem button onClick={()=>this.redirectTo("/")}>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItem>
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('company') !== -1 || scopes.indexOf('readCompany') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/company")}>
            <ListItemIcon>
                <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Companies" />
        </ListItem>}
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('video') !== -1 || scopes.indexOf('readVideo') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/video")}>
            <ListItemIcon>
                <YouTubeIcon />
            </ListItemIcon>
            <ListItemText primary="Videos" />
        </ListItem>}
        <Divider />
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('country') !== -1 || scopes.indexOf('readCountry') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/country")}>
            <ListItemIcon>
                <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary="Countries" />
        </ListItem>}
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('industry') !== -1 || scopes.indexOf('readIndustry') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/industry")}>
            <ListItemIcon>
                <DnsIcon />
            </ListItemIcon>
            <ListItemText primary="Industries" />
        </ListItem>}
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('quarter') !== -1 || scopes.indexOf('readQuarter') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/quarter")}>
            <ListItemIcon>
                <DonutLargeIcon />
            </ListItemIcon>
            <ListItemText primary="Quarters" />
        </ListItem>}
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('videotype') !== -1 || scopes.indexOf('readVideotype') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/videotype")}>
            <ListItemIcon>
                <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Video Types" />
        </ListItem>}
        <Divider />
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('user') !== -1 || scopes.indexOf('readUser') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/user")}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
        </ListItem>}
        {scopes && (scopes.indexOf('root') !== -1 || scopes.indexOf('readAudits') !== -1) && <ListItem button onClick={()=>this.redirectTo("/read/audits")}>
            <ListItemIcon>
                <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Audit Log" />
        </ListItem>}
    </List>
    }
}

export default SideMenu