import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from '../_helpers'
import { PrivateRoute, Alertify } from '../_components';
import { LoginPage, ActivatePage, ResetPage, CreatePage, ReadPage, AssignPage, RgUserPage, CompanyPage } from '../_pages'

function App() {
  return (
    <Router history={history} basename={'/'}>
        <Switch>
            {/* <PrivateRoute exact path="/" component={DashboardPage}/> */}

            <PrivateRoute exact path="/read/:document" component={ReadPage}/>
            <PrivateRoute exact path="/create/:document" component={CreatePage}/>
            <PrivateRoute exact path="/update/:document" component={CreatePage}/>
            <PrivateRoute exact path="/assign/:document/:ownerId" component={AssignPage}/>
            <PrivateRoute exact path="/rguser/:document/:ownerId" component={RgUserPage}/>
            <Route exact path="/activate/:token" component={ActivatePage}/>
            <Route exact path="/reset/:token" component={ResetPage}/>
            <Route exact path="/company/:name" component={CompanyPage}/>
            <Route path="/login" component={LoginPage}/>
            <Redirect from="/" to="/read/video" />
            <Redirect from="*" to="/" />
        </Switch>
        <Alertify />
    </Router>
  );
}

export default App;