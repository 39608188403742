import { userConstants } from '../_constants'

let user_ = JSON.parse(localStorage.getItem('user'));
const initialState = user_ ? { loggedIn: true, user:user_ } : {};

export function user(state=initialState, action){
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            }
        case userConstants.LOGIN_FAILURE:
            return {
                error: action.error
            }
        case userConstants.LOGOUT:
            return {}
        default:
            return state;
    }
}