import { localDB, store } from './'
import { userActions } from '../_actions'

export async function scopeCheck(Model) {
    const scopes = await localDB.get('scope');
    
    if (scopes === undefined)
        store.dispatch(userActions.logout())
    let permissions = {
        create: false,
        read: false,
        update: false,
        delete: false,
        getPermissions: false,
        addPermissions: false,
        removePermissions: false,
        getUsers: false,
        addUsers: false,
        removeUsers: false,
        canActivate: false,
        canDeactivate: false,
        canEnable: false,
        canDisable: false,
        scopes: scopes
    }

    if (Model === 'User' || Model === 'Role' || Model === 'Group') {
        if (scopes.indexOf('get' + Model + 'Permissions') !== -1) permissions.getPermissions = true
        if (scopes.indexOf('add' + Model + 'Permissions') !== -1) permissions.addPermissions = true
        if (scopes.indexOf('remove' + Model + 'Permissions') !== -1) permissions.removePermissions = true
    }

    if (Model === 'Role' || Model === 'Group') {
        if (scopes.indexOf('get' + Model + 'Users') !== -1) permissions.getUsers = true
        if (scopes.indexOf('add' + Model + 'Users') !== -1) permissions.addUsers = true
        if (scopes.indexOf('remove' + Model + 'Users') !== -1) permissions.removeUsers = true
    }


    if (scopes.indexOf(Model.toLowerCase()) !== -1) {
        permissions.create = true
        permissions.read = true
        permissions.update = true
        permissions.delete = true
    }

    if (scopes.indexOf('create' + Model) !== -1) permissions.create = true
    if (scopes.indexOf('read' + Model) !== -1) permissions.read = true
    if (scopes.indexOf('update' + Model) !== -1) permissions.update = true
    if (scopes.indexOf('delete' + Model) !== -1) permissions.delete = true

    if (Model === 'User') {
        if (scopes.indexOf('enableUser') !== -1) permissions.canEnable = true
        if (scopes.indexOf('disableUser') !== -1) permissions.canDisable = true
        if (scopes.indexOf('activateUser') !== -1) permissions.canActivate = true
        if (scopes.indexOf('deactivateUser') !== -1) permissions.canDeactivate = true
    }


    if (scopes.indexOf('root') !== -1 && scopes.indexOf('Super Admin') !== -1) {
        permissions.read = true
        if(Model !== 'Audits'){
            permissions.create = true
            permissions.update = true
            permissions.delete = true
        }

        if (Model === 'User') {
            permissions.canActivate = true
            permissions.canEnable = true
            permissions.canDeactivate = true
            permissions.canDisable = true
        }

        if (Model === 'User' || Model === 'Role' || Model === 'Group') {
            permissions.getPermissions = true
            permissions.addPermissions = true
            permissions.removePermissions = true
        }

        if (Model === 'Role' || Model === 'Group') {
            permissions.getUsers = true
            permissions.addUsers = true
            permissions.removeUsers = true
        }
    }

    return permissions
}