import React from 'react'
import { scopeCheck, apiService, history, document } from '../../_helpers'
import DataTable from 'react-data-table-component'
import pluralize from 'pluralize'
import { IconButton, Paper, InputBase } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import { withSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = (theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        marginLeft: theme.spacing(2)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    marginRight: {
        marginRight: '10px'
    }
});

class PermissionsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            create: false,
            read: false,
            update: false,
            delete: false,

            data: false,
            loading: false,
            page: 1,
            totalRows: 0,
            perPage: 10,
            filter: false,

            ownerId: this.props.data._id,
            ownerData: this.props.data,
            ownerDoc: this.props.document,
            selectedRows: [],
            toggledClearRows: false
        }

        this.document = 'assignPermission'
        this.title = pluralize(this.document)
        this.embedUrl = ''

        this.contextActions = <span></span>

        this.updateScopes()
    }

    async updateScopes() {
        let table = this.state.ownerDoc.charAt(0).toUpperCase() + this.state.ownerDoc.slice(1)
        let scopes = await scopeCheck(table)
        if (scopes.getPermissions) {
            let document = await this.getDocument()

            this.embedUrl = document.embedUrl

            let data = await this.getData()

            let btns = this.preRenderColumns(scopes)
            let ctxtBtns = this.contextMenu()
            let cols = [...document.response.columns]
            if (scopes.removePermissions) {
                this.contextActions = <span>
                    {ctxtBtns.DeleteCtxtBtn}
                </span>
                cols.push(btns.deleteBtn)
            }

            this.setState({ ...scopes, properName: table, page: 1, columns: cols, embeds: document.response.embeds, data: data.data.docs, totalRows: data.data.items.total, loading: false }, () => {
            })
        }
    }

    async getDocument() {
        let embedUrl = ''
        let response = await document(this.document)
        for (const key in response.embeds) {
            if (response.embeds.hasOwnProperty(key)) {
                embedUrl += `&$embed=${response.embeds[key]}`;
            }
        }
        return { response, embedUrl }
    }

    contextMenu(scopes) {
        const DeleteCtxtBtn = <IconButton
            color="secondary"
            onClick={() => this.deleteSelected()}
        >
            <DeleteIcon />
        </IconButton>

        return { DeleteCtxtBtn }
    }

    preRenderColumns() {
        let deleteBtn = {
            name: 'Remove',
            button: true,
            cell: row => {
                return <IconButton color="secondary" onClick={() => this.onDelete(row._id)}>
                    <DeleteIcon />
                </IconButton>
            },
        }

        return { deleteBtn }
    }

    async getData() {
        const { perPage, page, filter, ownerDoc, ownerId } = this.state;

        this.setState({ loading: true });
        let text = ''
        if (filter) {
            text = `&$text=${filter}`
        }

        let data = await apiService.get(`${ownerDoc}/${ownerId}/permission?$page=${page}&$limit=${perPage}${this.embedUrl}${text}`)
        return data
    }

    handlePageChange = async page => {
        const { perPage, filter, ownerDoc, ownerId } = this.state;

        this.setState({ loading: true, page });

        let text = ''
        if (filter) {
            text = `&$text=${filter}`
        }

        const response = await apiService.get(`${ownerDoc}/${ownerId}/permission?$page=${page}&$limit=${perPage}${this.embedUrl}${text}`)

        this.setState({
            loading: false,
            data: response.data.docs,
        });
    }

    handlePerRowsChange = async (perPage, page) => {
        this.setState({ loading: true, page });
        const { filter, ownerDoc, ownerId } = this.state;
        let text = ''
        if (filter) {
            text = `&$text=${filter}`
        }

        const response = await apiService.get(`${ownerDoc}/${ownerId}/permission?$page=${page}&$limit=${perPage}${this.embedUrl}${text}`)

        this.setState({
            loading: false,
            data: response.data.docs,
            perPage
        });
    }

    handleRowSelected = (selected) => {
        this.setState({ selectedRows: selected.selectedRows, toggledClearRows: false })
    }

    rowDisabledCriteria = row => row.isDeleted

    onDelete(id) {
        const { ownerDoc, ownerId } = this.state;
        if (this.state.removePermissions) {
            apiService.delete(`${ownerDoc}/${ownerId}/permission`, { data: [id] }).then(response => {
                this.props.enqueueSnackbar(`Permission removed successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        }
    }

    deleteSelected() {
        const { selectedRows, toggledClearRows, ownerDoc, ownerId } = this.state;
        const rows = selectedRows.map(r => r._id);
        this.setState({ toggledClearRows: !toggledClearRows })
        if (this.state.delete) {
            apiService.delete(`${ownerDoc}/${ownerId}/permission`, { data: rows }).then(response => {
                this.props.enqueueSnackbar(`Permissions removed successfully!`, {
                    variant: "info",
                })
                this.refreshData()
            }).catch(error => {
                console.log(error)
            })
        } else {
            this.props.enqueueSnackbar(`Delete access not allowed!`, {
                variant: "error",
            })
        }
    }

    filterText(text) {
        this.setState({ filter: text })
    }

    search() {
        const { perPage, page, filter, ownerDoc, ownerId } = this.state;

        this.setState({ loading: true });
        apiService.get(`${ownerDoc}/${ownerId}/permission?$page=${page}&$limit=${perPage}&$text=${filter}${this.embedUrl}`).then(response => {
            this.setState({ data: response.data.docs, totalRows: response.data.items.total, loading: false })
        }).catch(error => {
            console.log(error)
        })
    }

    async refreshData() {
        this.setState({ filter: false, loading: true })
        const { perPage, page, ownerDoc, ownerId } = this.state;
        let text = ''

        const response = await apiService.get(`${ownerDoc}/${ownerId}/permission?$page=${page}&$limit=${perPage}${this.embedUrl}${text}`)

        this.setState({
            loading: false,
            data: response.data.docs,
            totalRows: response.data.items.total,
        });
    }

    redirectTo(to) {
        history.push(to);
    }

    render() {
        let { addPermissions, read, data, totalRows, loading, columns, filter, toggledClearRows, ownerData, ownerDoc, ownerId } = this.state
        let title = `Assigned Permissions for ${ownerData.name?ownerData.name:ownerData.firstName}`
        if (!filter) filter = ''
        let { classes } = this.props
        return (
            <div>

                {
                    read && data && <DataTable
                        title={
                            <Box display="flex" p={1}>
                                <Box p={1} flexGrow={1}>
                                    {title}
                                </Box>
                                <Box p={1} flexGrow={1}>
                                    <Paper component="div" className={classes.root}>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Search permissions"
                                            inputProps={{ 'aria-label': 'search' }}
                                            value={filter}
                                            onChange={(e) => this.filterText(e.target.value)}
                                        />
                                        <IconButton type="reset" className={classes.iconButton} aria-label="search" onClick={() => this.search()}>
                                            <SearchIcon />
                                        </IconButton>
                                        {
                                            filter && <IconButton type="reset" className={classes.iconButton} aria-label="reset" onClick={() => this.refreshData()}>
                                                <ClearIcon />
                                            </IconButton>
                                        }
                                    </Paper>
                                </Box>
                                <Box p={1}>
                                    {addPermissions && <button className='btn btn-primary' onClick={() => this.redirectTo(`/assign/${ownerDoc}/${ownerId}`)}>Assign Permission</button>}
                                </Box>
                            </Box>
                        }
                        columns={columns}
                        fixedHeader
                        fixedHeaderScrollHeight="500px"
                        data={data}
                        selectableRows={this.document !== 'user'}
                        onSelectedRowsChange={this.handleRowSelected}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        highlightOnHover
                        contextActions={this.document !== 'user' && this.contextActions}
                        clearSelectedRows={toggledClearRows}
                    />
                }
            </div>
        );
    }
}

export default withSnackbar((withStyles(useStyles)(PermissionsComponent)))