const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true
    }
  ]
  
  const JSONschema = {
    type: "object",
    "required": ['name'],
    properties: {
      name: {
        type: "string",
        title: "Name"
      }
    }
  }
  
  const formData = ['name']
  
  export const videotype = { columns, JSONschema, formData }