import React from 'react'
import { apiService } from '../../_helpers'
import { Copyright } from '../../_components'

import { Typography, Card, CardContent, Grid, Hidden, Avatar, Button, Container, InputBase, Paper, IconButton, Box, LinearProgress } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { withStyles } from '@material-ui/core/styles'
import BusinessIcon from '@material-ui/icons/Business'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'

const fromTop = 165;

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        paddingTop: fromTop
    },
    chips: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    grid:{
        width: '60%',
        margin: '0 auto'
    },
    iframe: {
        position: 'relative',
        width: '100%',
        height: 400
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    right: {
        float: 'right'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    header: {
        position: 'fixed',
        background: '#fff',
        zIndex: 9,
        width: '100%'
    },
    search: {
        padding: '1px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        border: '1px solid #000'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
});


class CompanyPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: false,
            vids: [],
            pages: {},
            page: 1,
            comp: false,
            vid: false,
            filter: false,
            perPage: 10,
            openApp: true,
            loading: true
        }
        this.company = this.props.match.params.name
        this.getVideos = this.getVideos.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.clearFilter = this.clearFilter.bind(this)
        this.getVideos()
    }

    onPageChange(evt, page) {
        this.setState({ page: page, loading: true }, () => {
            this.getVideos()
        })
    }

    async getVideos() {
        const { perPage, page, filter, openApp } = this.state;
        let url = '';
        if (!filter)
            url = `compvids/${this.company}?$page=${page}&$limit=${perPage}`
        else
            url = `compvids/${this.company}?$page=${page}&$limit=${perPage}&$text=${filter}`
        let data = await apiService.get(url)
        this.setState({ name: data.data.comp.name, comp: data.data.comp, vids: data.data.vids.docs, pages: data.data.vids.pages, vid: data.data.vids.docs[0], page: page, loading: false }, () => {
            if (openApp)
                this.openApp()
        })
    }

    openApp() {
        this.setState({ openApp: false })
        if (/Android/i.test(navigator.userAgent)) {
            window.location.replace(`cac://company/${this.company}`)
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            window.location.replace(`cac://company/${this.company}`)
        }

    }

    filterText(text) {
        this.setState({ filter: text })
    }

    clearFilter() {
        this.setState({ filter: false, page: 1, loading: true }, () => {
            this.getVideos()
        })
    }

    search() {
        this.setState({ page: 1, loading: true }, () => {
            this.getVideos()
        })
    }

    searchOnEnter(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    render() {
        let { name, vids, pages, page, comp, filter, loading } = this.state;
        let { classes } = this.props
        if (!filter) filter = ''
        return (
            <div>
                <div className={classes.header}>
                    <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                        <Grid item md={3} >
                            <Box display="flex">
                                <Avatar className={classes.large}>
                                    {!comp.icon && <BusinessIcon />}
                                    {comp.icon && <img alt={comp.name} src={comp.icon} width="100%"/>}
                                </Avatar>
                                <Box ml={1} mt={3}>
                                    <h3>{name}</h3>
                                    {/* <div className={classes.chips}>
                                        {comp.country && <Chip label={comp.country.name} />}
                                        {comp.industry && <Chip label={comp.industry.name} />}
                                    </div> */}
                                </Box>
                            </Box>
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={6} ></Grid>
                        </Hidden>
                        <Grid item md={1}>
                            <Box display="flex">
                                <div><img alt="Logo" src="./logo.png" width="70px" /></div>
                                {/* <Box ml={1} mt={2} whiteSpace="nowrap"><RoomOutlinedIcon />
                                    <b>Investor Relations Advisory</b>
                                    <br />
                                    Middle East | India | Asia Pacific
                                </Box> */}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Grid container justify="center" alignItems="center">
                            <Paper component="div" className={classes.search}>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Search data"
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={filter}
                                    onChange={(e) => this.filterText(e.target.value)}
                                    onKeyPress={(e) => this.searchOnEnter(e)}
                                />
                                <IconButton type="reset" className={classes.iconButton} aria-label="search" onClick={() => this.search()}>
                                    <SearchIcon />
                                </IconButton>
                                {
                                    filter && <IconButton type="reset" className={classes.iconButton} aria-label="reset" onClick={() => this.clearFilter()}>
                                        <ClearIcon />
                                    </IconButton>
                                }
                            </Paper>
                        </Grid>
                        {loading && <LinearProgress color="secondary"/>}
                    </Box>
                </div>
                <div className={classes.root}>
                    <Container className={classes.content}>
                        <Grid container className={classes.grid} justify="flex-start" spacing={2}>
                            {
                                vids.map((vid, i) => {
                                    return <Grid xs={12} item key={vid._id}>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                { vid.report && <Button size="small" target="_blank" className={classes.right} color="primary" variant="outlined" href={vid.report}>
                                                    VIEW REPORT </Button> }
                                                <Typography gutterBottom component="h4">
                                                    <b>{vid.videoType && vid.videoType.name}, {vid.quarter && vid.quarter.name}</b>
                                                </Typography>
                                                {vid.description && <Typography gutterBottom component="h6">
                                                    {vid.description}
                                                </Typography>}
                                                <hr />
                                                <iframe title={vid._id} className={classes.iframe} width="16" height="9" src={`https://www.youtube.com/embed/${vid.url}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <br />
                        <Pagination count={pages.total} page={page} onChange={this.onPageChange} />
                        <Copyright />
                    </Container>
                </div>

            </div>

        );
    }
}

export default withStyles(useStyles)(CompanyPage)