import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'

export const Alertify = () => {
    const { enqueueSnackbar } = useSnackbar();
    const alert = useSelector(state => state.alert)
    if (alert.message)
        enqueueSnackbar(alert.message, {
            variant: alert.type,
        })
    return null
}